import React, { useState, useEffect, useRef, useContext } from "react"
import { globalHistory } from "@reach/router"
import { Link } from "gatsby"
import classNames from "classnames"
import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import popupHelper from "@nutrafol/popup-helper"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { TextElementNova } from "@nutrafol/nutrafol-ui-kit/dist/TextElementNova"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import { data, accordionNavigationData } from "./data"

import AccordionNavigation from "./accordionnavigation"
import Logo from "./logo"
import HeaderIcons from "./headericons"
import Ribbon from "./ribbon"
import HeaderAtcVariant from "../atcoptions/header-atc-variant"
import HeaderAtcCms from "../../scalp/atcoptions/header-atc"
import LowerWrap from "./headerlowerwrap"
import UserContext from "../../../../context/UserContext"
import { isProductPath } from "../../../../utils/general"
import SiteSwitch from "./siteswitch"
import NavSwitch from "./navswitch"
import MobileNavFlex from "./mobilenavflex"
import Search from "../../../search/search"
import { SearchContext } from "../../../search/searchcontext"

import {
  AboveHeaderWrap,
  SiteSwitchHolder,
  HeaderWrap,
  HeaderContainer,
  ContentsContainer,
  NavMobile,
  tabletFold,
} from "./header.styled"
import ArrowLeft from "../../../../assets/icons/nova/arrow-left.svg"

const ctaText = "Take the Quiz"
const NovaHeader = (props) => {
  const isMouseInHeader = useRef(false)
  const [navIsOpen, setNavIsOpen] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [withRibbon, setWithRibbon] = useState(props.withRibbon)
  const [headerRibbonData, setHeaderRibbonData] = useState()

  const userState = useContext(UserContext)
  const { isSearchVisible, closeSearchDropdown } = useContext(SearchContext)
  const { location } = props
  const pathname = props.location && props.location.pathname
  let PROMO_VARIANT = props.PROMO_VARIANT
  const contentfulHeaderRibbon = props.contentfulHeaderRibbon
  const isGwpLp = /travel-kit-offer/.test(props.location.pathname) || false
  let isAccountPage = false
  let isQuizLp = false
  let showCart = true
  let isFHK = false
  let isTarget = false

  if (pathname) {
    showCart = pathname.indexOf("/checkout") === -1
    isAccountPage = pathname.indexOf("account/") > -1
    isQuizLp = pathname.indexOf("hair-wellness-quiz-intro") > -1
    isFHK = pathname === "/hair-kit/"
    isTarget = pathname === contentfulHeaderRibbon?.ribbonLink
  }
  //NT2-22394 Ribbon | Ribbon updated for PDPs
  if (isTarget) {
    PROMO_VARIANT = `ribbon_products`
  }
  useEffect(() => {
    setWithRibbon(props.withRibbon || isTarget)
    if (isFHK) {
      setHeaderRibbonData(null)
    } else {
      setHeaderRibbonData(contentfulHeaderRibbon)
    }
  }, [contentfulHeaderRibbon, isFHK, pathname])

  // Reset search on route change
  useEffect(() => {
    return globalHistory.listen(() => {
      closeSearchDropdown(false)
    })
  }, [closeSearchDropdown])

  const isZeLoaded = () => !!(window.zE && typeof window.zE.show === "function")
  const isAdaLoaded = () => !!window.adaEmbed
  const isAttentiveLoaded = () => !!window.attentive_overlay

  const toggleMobileNav = (isOpen = false) => {
    segmentEvent("Hamburger Navigation Menu Clicked", {
      module_location: "navigation",
      path: "/",
    })

    if (isAttentiveLoaded()) {
      try {
        window.attentive_overlay.style.zIndex = isOpen ? "10" : "2147483647"
      } catch (error) {
        console.error("attentive_overlay  zIndex failed ", error)
      }
    }
    if (!isOpen) {
      setNavIsOpen(false)
      // toggleScroll(true)
      setMenuIsOpen(false)
      if (isZeLoaded()) {
        window.zE.show()
      } else if (isAdaLoaded()) {
        const adaBtn = document.getElementById("ada-button-frame")
        if (adaBtn) {
          adaBtn.style.display = "block"
        }
      }
    } else {
      setNavIsOpen(true)
      // toggleScroll(false)
      if (isAdaLoaded()) {
        const adaBtn = document.getElementById("ada-button-frame")
        if (adaBtn) {
          adaBtn.style.display = "none"
        }
      } else if (isZeLoaded()) {
        window.zE.hide()
      } else {
        // if menu is tapped within 3 seconds of page load - wait for zE and hide it
        let zeInterval = setInterval(() => {
          if (isZeLoaded()) {
            window.zE.hide()
            clearInterval(zeInterval)
          }
        }, 250)
      }
    }
  }

  /*
   * Commenting out all toggleScroll references. This was causing bugs when navigating between pages with different headers and with opening/closing the search modal
   * const toggleScroll = (exists = false, searchScrollPosition) => {
   *   const bodyEl =
   *     typeof window !== "undefined" ? document.querySelector("body") : null
   */

  /*
   *   const htmlEl =
   *     typeof window !== "undefined" ? document.querySelector("html") : null
   */

  /*
   *   // Todo: Refactor scroll position for scroll / mobile bug
   *   if (typeof document !== "undefined" && bodyEl) {
   *     if (exists === true) {
   *       htmlEl.style.removeProperty("height")
   *       bodyEl.style.removeProperty("overflow")
   *       bodyEl.style.removeProperty("position")
   *       bodyEl.style.removeProperty("top")
   *       bodyEl.style.removeProperty("width")
   *       window.scrollTo(0, searchScrollPosition || scrollPosition)
   *       // document.body.removeEventListener('touchmove', props.mobileHandler, options)
   *     } else {
   *       const scrolledOffset = window.scrollY
   *       setScrollPosition(scrolledOffset)
   *       htmlEl.style.height = "100vh"
   *       bodyEl.style.overflow = "hidden"
   *       bodyEl.style.position = "fixed"
   *       // bodyEl.style.top = `-${scrolledOffset}px`
   *       bodyEl.style.width = "100%"
   *       // document.body.addEventListener('touchmove', props.mobileHandler, options)
   *     }
   *   }
   * }
   */

  if (typeof window !== "undefined") {
    // close menu on resize > 1024
    const handleResize = () => {
      const navEl = document.querySelector(".nav-open")
      if (navEl && window.innerWidth >= tabletFold) {
        toggleMobileNav(false)
      }
    }
    window.addEventListener("resize", handleResize)
  }

  const handleNavLogoExitClick = () => {
    // toggleScroll(true)
    toggleMobileNav(false)
    userState.setAtcData(null)
    userState.setActiveOption(0)
    segmentEvent("Navigation Clicked", {
      module_location: "navigation",
      destination: "/",
      type: "link",
    })
  }

  const [isPdp, setIsPdp] = useState(false)

  useEffect(() => {
    let adaChat = document?.querySelector("#ada-entry")
    const [banner] = document?.getElementsByClassName("video-banner-wrap")
    const video = banner?.getElementsByTagName("video")[0]

    /**
     * Sometimes 3rd party are loaded later,
     * might be a good idea to have a `GlobalContext`
     */
    if (!adaChat) {
      const intervalId = setInterval(() => {
        adaChat = document?.querySelector("#ada-entry")
        if (adaChat && isSearchVisible) {
          adaChat?.classList?.add("hidden")
          clearInterval(intervalId)
        }
      }, 0)
    }

    const bodyEl =
      typeof window !== "undefined" ? document.querySelector("body") : null

    if (isSearchVisible) {
      adaChat?.classList?.add("hidden")
      video?.pause()
      // toggleScroll(false)

      bodyEl.style.overflow = "hidden"

      return
    }

    if (!isSearchVisible) {
      bodyEl.style.removeProperty("overflow")
    }

    adaChat?.classList?.remove("hidden")
    if (!navIsOpen) {
      // toggleScroll(true, searchScrollPosition)
    }
    video?.play()
  }, [isSearchVisible])

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsPdp(isProductPath(window.location.pathname))
    }
  }, [userState.atcData])

  useEffect(() => {
    let rect = { y: 0 }
    let gap = popupHelper.isMobile() ? 0 : 40

    const handleScroll = () => {
      // all pages are setting .pagescrolled class
      const val = document.documentElement.scrollTop
      const classList = document.body.classList
      const pageScrolled = classList.contains("pagescrolled")
      const pageScrolledAtc = classList.contains("stickyatc")

      if (!isMouseInHeader.current) {
        if (val > 85) {
          if (!pageScrolled) {
            classList.add("pagescrolled")
          }
        } else {
          if (pageScrolled) {
            classList.remove("pagescrolled")
          }
        }
      }
      // PDP pages are using setAtcData() via context and setting .stickyatc class
      if (isProductPath(window.location.pathname)) {
        document.querySelectorAll(".buy-once").forEach((ela) => {
          if (ela && ela.offsetParent && ela.offsetTop) {
            rect = ela.getBoundingClientRect()
          }
        })
        if (rect.y - gap < 0) {
          if (!pageScrolledAtc) {
            classList.add("stickyatc")
          }
        } else {
          if (pageScrolledAtc) {
            classList.remove("stickyatc")
          }
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isPdp])

  const ArrowFlex = styled(Flex)`
    svg {
      width: 16px;
      height: 16px;
    }

    width: fit-content;
    border-bottom: 1px #041c1b solid;
  `

  return (
    <UserContext.Consumer>
      {(state) => (
        <HeaderWrap
          isLoggedIn={userState.isLoggedIn}
          data-mousedown
          className={classNames("header-wrap", {
            "nav-open": navIsOpen,
            "has-ribbon": withRibbon,
          })}
          onMouseEnter={() => (isMouseInHeader.current = true)}
          onMouseLeave={() => (isMouseInHeader.current = false)}
          onTouchEnd={() =>
            setTimeout(() => {
              isMouseInHeader.current = false
            })
          }
          onClick={(e) => {
            // Prevents closing nav when header is clicked
            if (isSearchVisible) {
              e.stopPropagation()
            }
            isMouseInHeader.current = false
          }}
        >
          {!isAccountPage ? (
            withRibbon && headerRibbonData ? (
              <AboveHeaderWrap flexWrap="wrap" className={`ribbon-container`}>
                <SiteSwitchHolder
                  width={[1, 1, 300]}
                  className={isGwpLp ? "!hidden" : ""}
                >
                  <SiteSwitch />
                </SiteSwitchHolder>
                <Box
                  width={[0, 0, "calc(100% - 300px)"]}
                  className="show-lg-up"
                >
                  <Ribbon
                    contentfulHeaderRibbon={contentfulHeaderRibbon}
                    isMouseInHeader={isMouseInHeader}
                    PROMO_VARIANT={PROMO_VARIANT}
                    location={location}
                  />
                </Box>
              </AboveHeaderWrap>
            ) : (
              <Box className={`no-ribbon-switch ${isGwpLp ? "!hidden" : ""}`}>
                <SiteSwitchHolder width={[1]}>
                  <SiteSwitch className={`rounded-tr-[4px]`} />
                </SiteSwitchHolder>
              </Box>
            )
          ) : null}
          <HeaderContainer className="container container-master ">
            <div className="stickyatcheader">
              {isPdp ? (
                <HeaderAtcVariant
                  state={state}
                  handleNavLogoExitClick={handleNavLogoExitClick}
                />
              ) : (
                <HeaderAtcCms state={state} />
              )}
            </div>
            <ContentsContainer className="nonstickyheader wrap">
              {isAccountPage ? (
                <Flex
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent={"space-between"}
                  height={[56, 56, 56, 80]}
                >
                  <Box width={[0.3]} className="left-wrap-account">
                    <Link to={"/"}>
                      <ArrowFlex ml={[16, 24, 24]}>
                        <Box>
                          <ArrowLeft />
                        </Box>
                        <Box>
                          <TextElementNova
                            element="p"
                            text={"Shop"}
                            className="Body-20-Dia-Normal text-nova-base-black"
                          />
                        </Box>
                      </ArrowFlex>
                    </Link>
                  </Box>
                  <Box
                    width={["fit-content"]}
                    ml={[16, null, null, "unset"]}
                    className="center-wrap-account"
                  >
                    <Logo
                      handleNavLogoExitClick={handleNavLogoExitClick}
                      isAccountPage={isAccountPage}
                    />
                  </Box>
                  <Box width={[0.3]} className="right-wrap-account">
                    <Flex
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <HeaderIcons
                        navIsOpen={navIsOpen}
                        showCart={showCart}
                        isProductPage={isPdp}
                        isAccountPage={isAccountPage}
                        isQuizLp={isQuizLp}
                        toggleMobileNav={toggleMobileNav}
                        location={location}
                      />
                    </Flex>
                  </Box>
                </Flex>
              ) : (
                <>
                  <div className="mobile-nav-flex">
                    <NavMobile flexWrap="wrap" alignItems="center" width={[1]}>
                      <NavSwitch type="men" copy="Men" />
                      <NavSwitch
                        type="skin"
                        copy="Skin"
                        external={true}
                        isGwpLp={isGwpLp}
                      />
                      {userState?.isLoggedIn ? (
                        <NavSwitch
                          type="account"
                          copy={"My Account"}
                          external={false}
                          isGwpLp={isGwpLp}
                        />
                      ) : (
                        <NavSwitch
                          type="quiz"
                          copy={ctaText}
                          external={true}
                          isGwpLp={isGwpLp}
                        />
                      )}
                    </NavMobile>
                  </div>
                  <MobileNavFlex
                    data={data}
                    isAccountPage={isAccountPage}
                    navIsOpen={navIsOpen}
                    isPdp={isPdp}
                    isQuizLp={isQuizLp}
                    handleNavLogoExitClick={handleNavLogoExitClick}
                    showCart={showCart}
                    isMouseInHeader={isMouseInHeader}
                    toggleMobileNav={toggleMobileNav}
                    isIconVisibleOnRightSideOnly
                  />
                  {withRibbon && headerRibbonData ? (
                    <div className={`hide-lg-up ribbon-container`}>
                      <Ribbon
                        contentfulHeaderRibbon={contentfulHeaderRibbon}
                        isMouseInHeader={isMouseInHeader}
                        PROMO_VARIANT={PROMO_VARIANT}
                        location={location}
                      />
                    </div>
                  ) : null}
                </>
              )}
            </ContentsContainer>
            {userState.isLoggedIn ? (
              <LowerWrap
                navIsOpen={navIsOpen}
                showCart={showCart}
                // isAccountPage={isAccountPage || isSupportPage}
                isAccountPage={isAccountPage}
                toggleMobileNav={() => toggleMobileNav(!navIsOpen)}
                userName={userState?.customer?.firstname}
                textStyle={{ color: theme.wild.color.nova.base.black }}
              />
            ) : null}
          </HeaderContainer>
          {navIsOpen ? (
            <AccordionNavigation
              data={accordionNavigationData}
              toggleNav={setNavIsOpen}
              navIsOpen={navIsOpen}
              menuIsOpen={menuIsOpen}
            />
          ) : null}
          {isSearchVisible ? <Search /> : null}
        </HeaderWrap>
      )}
    </UserContext.Consumer>
  )
}

export default NovaHeader
