export const GP1SUBSCRIBERSSEGMENT = "gp_1_subscribers_1_3_month"
export const GP1SUBSCRIBERSSEGMENTFREEGIFT =
  "gp_1_subscribers_1_3_month_free_gift"
export const FATHERSDAYRETENTIONPAIDADSAUDIENCE2024 =
  "2024_fathers_day_retention_paid_ads_audience"
export const GP1MONTHLYSUBSSEGMENT = "gp_1_monthly_subs"
export const GP1MONTHLYSUBSGREEGIFT = "gp_1_monthly_subs_free_gift"
export const GP1MONTHLYSUBSFREEGIFTOCT = "gp_1_monthly_subs_free_gift_october"
export const BFCM2024SEGMENT = "gp_1_subscribers_1_3_month"

//NT2-22283 Retention | February Account Upgrade 2/10/2025
export const FEBMASTER20SEGMENT =
  "feb_25_upgrade_gwp_20_off_6_m_womens_shp_cond_serum"
export const FEBMASTER25SEGMENT =
  "feb_25_upgrade_gwp_25_off_6_m_womens_shp_cond_serum"
export const FEBMEN20SEGMENT = "feb_25_upgrade_gwp_20_off_6_m_mens_2_in_1_serum"
export const FEBMEN25SEGMENT = "feb_25_upgrade_gwp_25_off_6_m_mens_2_in_1_serum"

export const FEBMASTER20SEGMENTACCEPTED =
  "FEB25_Upgrade_GWP_20Off_6M_WomensShpCondSerum_accepted"
export const FEBMASTER25SEGMENTACCEPTED =
  "FEB25_Upgrade_GWP_25Off_6M_WomensShpCondSerum_accepted"
export const FEBMEN20SEGMENTACCEPTED =
  "FEB25_Upgrade_GWP_20Off_6M_Mens2in1Serum_accepted"
export const FEBMEN25SEGMENTACCEPTED =
  "FEB25_Upgrade_GWP_25Off_6M_Mens2in1Serum_accepted"
