import gql from "graphql-tag"

export const getCustomerSubscriptionsAndAddresses = gql`
  query GetSubscriptions($entity_id: Int) {
    appointments(futureOnly: true) {
      id
      datetime
      type
      confirmationPage
    }
    customer {
      firstname
      lastname
      email
      entity_id
      gender
      refill_opt_in
      addresses {
        id
        firstname
        lastname
        company
        street
        city
        postcode
        telephone
        default_billing
        default_shipping
        country_id
        region {
          region_id
          region
        }
      }
      orders(pageSize: 250) {
        items {
          number
          order_date
          concierge_parent_subscription_id
          is_concierge
          shipments {
            tracking {
              number
            }
          }
          items {
            status
            id
            image
            label1
            product_name
            product_sku
            quantity_ordered
            product_sale_price {
              value
              currency
            }
          }
        }
      }
      store_credit {
        current_balance {
          value
          currency
        }
      }
    }
    tokenBaseCheckoutConfig(method: "authnetcim") {
      clientKey
      apiLoginId
      sandbox
    }
    tokenBaseCards {
      id
      label
      expires
      hash
      customer_email
      customer_id
      profile_id
      method
      active
      created_at
      address {
        region {
          region
          region_id
        }
        region_id
        country_id
        street
        company
        telephone
        postcode
        city
        firstname
        lastname
      }
      additional {
        cc_type
        cc_last4
        cc_exp_year
        cc_exp_month
      }
    }
    subscriptions(entity_id: $entity_id) {
      entity_id
      loyalty_campaigns {
        segment_name
        booster_accepted_list
        run_with_next_rebill
      }
      concierge_parent_subscription_id
      is_concierge
      custom_discount
      cancel_date
      increment_id
      name
      quote_id
      description
      customer_id
      created_at
      next_run
      last_run
      subtotal
      length
      status
      frequency_count
      frequency_unit
      product_growth_plan_data
      journey_start_date
      compliance_message
      is_california
      reactivate_compliance_message
      quiz_result {
        token
        created_at
      }
      doctor_details {
        name
        image
        acuity_id
      }
      logs {
        order_increment_id
      }
      additional {
        key
        value
      }
      quote {
        entity_id
        customer_email
        subtotal
        grand_total
        items {
          item_id
          sku
          name
          description
          product_id
          qty
          price
          row_total
        }
        billing_address {
          address_id
          customer_address_id
          region
          region_id
          country_id
          street
          company
          telephone
          postcode
          city
          firstname
          lastname
        }
        shipping_address {
          address_id
          customer_address_id
          region
          region_id
          country_id
          street
          company
          telephone
          postcode
          city
          firstname
          lastname
          subtotal
          grand_total
        }
        payment {
          tokenbase_id
          entity_id
          method
          cc_type
          cc_last_4
          cc_owner
          cc_exp_month
          cc_exp_year
          po_number
          additional_data {
            key
            value
          }
          additional_information {
            key
            value
          }
        }
      }
    }
    countries {
      id
      full_name_english
      available_regions {
        id
        code
        name
      }
    }
  }
`

export const getCustomerSubscriptionsAndOrders = gql`
  query GetSubscriptions($entity_id: Int) {
    customer {
      firstname
      lastname
      email
      entity_id
      gender
      refill_opt_in
      addresses {
        id
        firstname
        lastname
        company
        street
        city
        postcode
        telephone
        default_billing
        default_shipping
        country_id
        region {
          region_id
          region
        }
      }
      orders(pageSize: 250) {
        items {
          number
          order_date
          concierge_parent_subscription_id
          is_concierge
          shipments {
            tracking {
              number
            }
          }
          items {
            status
            id
            image
            label1
            product_name
            product_sku
            quantity_ordered
            product_sale_price {
              value
              currency
            }
          }
        }
      }
      store_credit {
        current_balance {
          value
          currency
        }
      }
    }

    subscriptions(entity_id: $entity_id) {
      entity_id
      loyalty_campaigns {
        segment_name
        booster_accepted_list
        run_with_next_rebill
      }
      concierge_parent_subscription_id
      is_concierge
      custom_discount
      cancel_date
      increment_id
      name
      quote_id
      description
      customer_id
      created_at
      next_run
      last_run
      subtotal
      length
      status
      frequency_count
      frequency_unit
      product_growth_plan_data
      journey_start_date
      compliance_message
      is_california
      reactivate_compliance_message
      quiz_result {
        token
        created_at
      }
      doctor_details {
        name
        image
        acuity_id
      }
      logs {
        order_increment_id
      }
      additional {
        key
        value
      }
      quote {
        entity_id
        customer_email
        subtotal
        grand_total
        items {
          item_id
          sku
          name
          description
          product_id
          qty
          price
          row_total
        }
        billing_address {
          address_id
          customer_address_id
          region
          region_id
          country_id
          street
          company
          telephone
          postcode
          city
          firstname
          lastname
        }
        shipping_address {
          address_id
          customer_address_id
          region
          region_id
          country_id
          street
          company
          telephone
          postcode
          city
          firstname
          lastname
          subtotal
          grand_total
        }
        payment {
          tokenbase_id
          entity_id
          method
          cc_type
          cc_last_4
          cc_owner
          cc_exp_month
          cc_exp_year
          po_number
          additional_data {
            key
            value
          }
          additional_information {
            key
            value
          }
        }
      }
    }
  }
`

export const emailSubscribeMutation = gql`
  mutation SubscribeForNewsletters($email: String!) {
    subscribeForNewsletters(email: $email) {
      result {
        status
        message
      }
    }
  }
`

export const removeCustomerSegmentKey = gql`
  mutation RemoveCustomerSegmentKeyMutation($segment_key_name: String!) {
    RemoveCustomerSegmentKey(segment_key_name: $segment_key_name) {
      id
      name
    }
  }
`

export const getNutrafolCustomerSegmentKey = gql`
  query {
    customer {
      segment_key {
        id
        name
      }
    }
  }
`

export const getCustomerOrders = gql`
  query {
    customer {
      firstname
      store_credit {
        current_balance {
          value
          currency
        }
      }
      orders(pageSize: 250) {
        items {
          id
          number
          status
          order_date
          items {
            image
            label1
            is_core
            product_name
            product_category
            core_category
            product_sku
            product_url_key
            product_sale_price {
              value
              currency
            }
            quantity_ordered
          }
          shipments {
            id
            tracking {
              number
              carrier
            }
          }
          total {
            grand_total {
              value
              currency
            }
          }
        }
      }
    }
  }
`

export const updateJourneyStartDate = gql`
  mutation UpdateJourneyStartDate($input: SubscriptionUpdateInput!) {
    updateSubscription(input: $input) {
      created_at
      customer_id
      journey_start_date
    }
  }
`

export const getCustomerOrderTracking = gql`
  query GetCustomerOrderTracking($order_id: String) {
    customer {
      orders(filter: { number: { eq: $order_id } }) {
        items {
          id
          number
          shipments {
            id
            number
            tracking {
              carrier
              number
              title
            }
          }
        }
      }
    }
  }
`

export const getProductsBySku = gql`
  query GetProductsBySku($sku1: String!, $sku2: String!, $sku3: String) {
    products(
      filter: { sku: { in: [$sku1, $sku2, $sku3] } }
      sort: { price: ASC }
    ) {
      items {
        id
        name
        image {
          url
        }
        label1
        price_range {
          minimum_price {
            regular_price {
              value
            }
          }
        }
        subscription_intervals
      }
    }
  }
`

export const upgradeOneMonthToThreeMonthSku = gql`
  mutation UpgradeOneMonthToThreeMonthSku($input: SubscriptionUpdateInput!) {
    updateSubscription(input: $input) {
      entity_id
      coupon_code
      quote {
        items {
          item_id
          product_id
        }
      }
    }
  }
`

export const getAllProducts = gql`
  query GetAllProducts {
    coreSubscriptionProductList {
      id
      sku
      bundle_keys
      price
      bundled_price
      name
      image
      label1
      subscription_intervals
    }
  }
`

export const getBoostersByBundleKey = gql`
  query GetBoostersByBundleKeys(
    $bundle_key_id: Int
    $subscription_interval: Int
  ) {
    boostersCombinationsByBundleKeyId(
      bundle_key_id: $bundle_key_id
      subscription_interval: $subscription_interval
    ) {
      keys
    }
    boostersProductListByCoreBundleKeyId(
      bundle_key_id: $bundle_key_id
      subscription_interval: $subscription_interval
    ) {
      id
      sku
      bundle_keys
      price
      bundled_price
      name
      image
      label1
      subscription_intervals
    }
  }
`

export const getProductByBundleKeys = gql`
  query GetProductByBundleKeys(
    $bundle_keys: [Int]
    $subscription_interval: Int
  ) {
    productByBundleKeys(
      bundle_keys: $bundle_keys
      subscription_interval: $subscription_interval
    ) {
      id
      sku
      bundle_keys
      bundled_price
      price
      name
      image
      label1
      subscription_intervals
    }
  }
`

export const updateSubscriptionProducts = gql`
  mutation UpdateSubscriptionMutation($input: SubscriptionUpdateInput!) {
    updateSubscription(input: $input) {
      entity_id
      name
      custom_discount
      increment_id
      quote_id
      description
      customer_id
      created_at
      next_run
      last_run
      subtotal
      length
      status
      frequency_count
      frequency_unit
      product_growth_plan_data
      journey_start_date
      compliance_message
      is_california
      reactivate_compliance_message
      quiz_result {
        token
        created_at
      }
      logs {
        order_increment_id
      }
      additional {
        key
        value
      }
      quote {
        entity_id
        customer_email
        subtotal
        grand_total
        items {
          item_id
          sku
          name
          description
          product_id
          qty
          price
          row_total
        }
        billing_address {
          address_id
          customer_address_id
          region
          region_id
          country_id
          street
          company
          telephone
          postcode
          city
          firstname
          lastname
        }
        shipping_address {
          address_id
          customer_address_id
          region
          region_id
          country_id
          street
          company
          telephone
          postcode
          city
          firstname
          lastname
          subtotal
          grand_total
        }
        payment {
          tokenbase_id
          entity_id
          method
          cc_type
          cc_last_4
          cc_owner
          cc_exp_month
          cc_exp_year
          po_number
          additional_data {
            key
            value
          }
          additional_information {
            key
            value
          }
        }
      }
    }
  }
`

export const addCustomerSegmentKey = gql`
  mutation AddCustomerSegmentKey($segment_key_name: String!) {
    AddCustomerSegmentKey(segment_key_name: $segment_key_name) {
      id
      name
    }
  }
`

export const estimateSubscriptionQuote = gql`
  mutation EstimateSubscriptionQuote(
    $subscription_entity_id: Int!
    $subscription_products: [SubscriptionProductsInput]!
    $coupon_code: String
  ) {
    estimateSubscriptionQuote(
      subscription_entity_id: $subscription_entity_id
      subscription_products: $subscription_products
      coupon_code: $coupon_code
    ) {
      grand_total
      subtotal
      discount_amount
      frequency_count
    }
  }
`
export const refillOptIn = gql`
  mutation RefillOptIn($opt_in: Boolean!) {
    updateCustomerV2(input: { refill_opt_in: $opt_in }) {
      customer {
        refill_opt_in
      }
    }
  }
`

export const updateSubName = gql`
  mutation UpdateSubName($input: SubscriptionUpdateInput!) {
    updateSubscription(input: $input) {
      entity_id
      name
    }
  }
`

export const getCustomerSubscription = gql`
  query GetSubscription($entity_id: Int) {
    subscriptions(entity_id: $entity_id) {
      entity_id
      custom_discount
      increment_id
      quote_id
      description
      customer_id
      created_at
      next_run
      last_run
      name
      subtotal
      length
      status
      frequency_count
      frequency_unit
      product_growth_plan_data
      journey_start_date
      compliance_message
      is_california
      reactivate_compliance_message
      quiz_result {
        token
        created_at
      }
      doctor_details {
        name
        image
        acuity_id
      }
      logs {
        order_increment_id
      }
      additional {
        key
        value
      }
      quote {
        entity_id
        customer_email
        subtotal
        grand_total
        items {
          item_id
          sku
          name
          description
          product_id
          qty
          price
          row_total
        }
        billing_address {
          address_id
          customer_address_id
          region
          region_id
          country_id
          street
          company
          telephone
          postcode
          city
          firstname
          lastname
        }
        shipping_address {
          address_id
          customer_address_id
          region
          region_id
          country_id
          street
          company
          telephone
          postcode
          city
          firstname
          lastname
          subtotal
          grand_total
        }
        payment {
          tokenbase_id
          entity_id
          method
          cc_type
          cc_last_4
          cc_owner
          cc_exp_month
          cc_exp_year
          po_number
          additional_data {
            key
            value
          }
          additional_information {
            key
            value
          }
        }
      }
    }
  }
`

export const getProductBySku = gql`
  query GetProductBySku($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        id
        sku
        name
        special_price
        old_price
        product_gender
        subscription_intervals
        label1 {
          url
        }
        price_range {
          minimum_price {
            regular_price {
              value
            }
          }
        }
      }
    }
  }
`

export const getLoyaltyProducts = gql`
  query GetProductsBySku($skus: [String!]!) {
    products(filter: { sku: { in: $skus } }, sort: { price: ASC }) {
      items {
        id
        name
        sku
        image {
          url
        }
      }
    }
  }
`

export const getCustomerCampaigns = gql`
  query {
    CustomerCampaigns {
      segment_name
      subscription_id
      is_accepted
      booster_list
      remaining_lifetime
      booster_accepted_list
      combined_product_id_list
    }
  }
`

export const acceptCampaignMutation = gql`
  mutation AcceptLoyaltyCampaign(
    $segment_name: String!
    $subscription_id: Int!
    $sku: String!
  ) {
    AcceptLoyaltyCampaign(
      segment_name: $segment_name
      subscription_id: $subscription_id
      sku: $sku
    ) {
      segment_name
      subscription_id
      is_accepted
      booster_list
      booster_accepted_list
      run_with_next_rebill
      remaining_lifetime
      combined_product_id_list
    }
  }
`

export const getSubscriptionsForLoyalty = gql`
  query GetSubscriptions($entity_id: [String!]!) {
    subscriptions(filter: { entity_id: { in: $entity_id } }) {
      entity_id
      name
      description
      product_growth_plan_data
    }
  }
`

export const getSubscriptionGrowthData = gql`
  query GetSubscriptions($entity_id: Int) {
    subscriptions(entity_id: $entity_id) {
      product_growth_plan_data
      description
    }
  }
`
