import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

export const AboveHeaderWrap = styled(Flex)`
  position: relative;
`

export const SiteSwitchHolder = styled(Box)`
  background: #fff;
  display: none;
  @media (min-width: 1024px) {
    display: block;
    background: transparent;
    //min-height: 40px;
  }
  @media (min-width: 1024px) {
    min-height: 34.5px;
  }
`

export const HeaderWrap = styled.header`
  background: transparent;

  @media (hover: hover) and (pointer: fine) and (min-width: 1024px) {
    /* Styles for non-touch devices only */
    a:focus,
    a:focus-visible {
      outline: auto !important;

      &.list-item {
        outline-offset: -2px;
      }
    }

    button:focus,
    button:focus-visible {
      outline: auto !important;
      outline-offset: -2px;
    }
  }

  border-bottom: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  @media (min-width: 1024px) {
    //box-shadow: none !important;
    border-top: 1px solid ${theme.wild.color.nova.transparency.black[20]};
    border-left: 1px solid ${theme.wild.color.nova.transparency.black[20]};
    border-right: 1px solid ${theme.wild.color.nova.transparency.black[20]};

    box-shadow:
      0px 1.75px 2.31px 0px #00000002,
      0px 8px 6.5px 0px #00000004,
      0px 20.25px 17.44px 0px #00000005,
      0px 40px 40px 0px #00000008;
  }
  &.has-ribbon {
    @media (min-width: 1024px) {
      margin-top: 0px;
    }
  }

  .toggler-gradient {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 4.93%
    );

    a {
      width: 100%;
      text-align: center;
      margin: auto;
      display: block;

      svg {
        display: inline;
        transform: translate(0, -1px);
      }
    }
  }

  .dropdown-shop-desktop {
    //left: -6px;
  }

  &.product-page {
    @media (max-width: 1023px) {
      box-shadow: none;
    }

    .container {
      background-color: rgba(255, 255, 255, 1);

      .stickyatcheader {
        height: 52px;

        @media (min-width: 1024px) {
          height: 64px;
        }
      }

      .stickyatcheader,
      .nonstickyheader {
        .navigation * {
          color: #07253a;
        }

        .navigation * .btn-green {
          color: #00857d !important;
        }

        .arrow-down *,
        .logo-wrap *,
        .right-wrap * {
          fill: #07253a !important;
        }

        .right-wrap .account-flex * {
          fill: #008078 !important;
        }

        #HamburgerOpenCloseIcon * {
          //stroke: #07253A !important;
        }
      }
    }
  }

  &.nav-open {
    .wrap {
      position: relative;

      .toggle-nav {
        margin-top: 0;
        margin-left: 0;

        span {
          transform: rotate(45deg) !important;
          top: 1.5rem;
          width: 20px;

          &:before {
            //background-color: transparent;
          }

          &:after {
            top: 0;
            transform: rotate(-90deg) !important;
          }
        }
      }
    }

    &.second-level-active {
      .back-wrapper {
        display: inline-block;
      }
    }
  }

  &[data-mousedown] {
    *:focus {
      outline: none;
    }
  }
`
export const HeaderContainer = styled.div`
  > .lower-wrap {
    height: 36px;
    padding: 6px 8px 10px 8px;

    &--customer-name {
      font-size: 12px;
      line-height: 16.8px;
    }
  }

  background: #464d52;
  padding: 0 !important;
  @media (min-width: 1024px) {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`
export const ContentsContainer = styled.div`
  @media (min-width: 1024px) {
    .left-wrap {
      //padding-left: 25px;
    }

    .center-wrap {
    }

    .right-wrap {
      padding-right: 24px;
    }
  }
`

export const BelowFoldContainer = styled.div`
  height: inherit;
  @media (min-width: 1024px) {
    padding: 8px 4px 0 0;
  }
`

export const MobileAlt = styled.button`
  display: contents;
  text-align: center;
  height: 56px;

  p[class*="mono--"] {
    line-height: 56px !important;
    height: 56px;
    color: #fff !important;
    background: #00857d;
    font-size: 18px;
    border-radius: 4px;
    width: calc(100% - 16px);
    margin: 8px auto;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

export const NavVar1 = styled(Flex)`
  height: 44px;
  align-items: stretch;
  button.textLink {
    font-size: 12px !important;
  }

  .takequiz {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0;
    background: #007a73;

    .textLink {
      color: #fff !important;
      border: 0 !important;
      text-transform: initial !important;
      width: 100%;
      height: 100%;
      span {
        font-family: "Dinamo Regular", Helvetica, Arial, sans-serif;
      }
    }
  }
`

export const NavSwitcherFlex = styled(Flex)`
  .flex {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }

  //Use Media Queries for Touch-Specific Styling
  @media (hover: none) and (pointer: coarse) {
    &:active {
      background-color: gray;
    }
  }

  &:hover {
    background-color: ${theme.wild.color.nova.base.solid[20]};
  }

  border: 1px ${theme.wild.color.nova.transparency.black[20]} solid;

  &.skin {
    border-left: 0;
  }

  a {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: #041c1b;
    }
  }
`
